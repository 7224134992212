import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';

export const ProductCardContainer = styled.section`

    .legal-container-images {
        display: flex;
        align-items: center;

        picture {
            width: auto;
            height: auto;
        }

        picture:first-child {
            width: 200px;
            height: 33px;
        }

        picture:last-child {
            width: 114px;
            height: 30px;
        }
    }

    ${breakpointsMedia({
    xs: css`
        display: grid;
        justify-content: center;
        align-items: center;
        width: 100%;
        padding: 24px;
        grid-template-areas: "card";

        #show-product-btn {
            color: ${({ btnColor }) => btnColor}
        }

        .card {
            background-color: ${({ color }) => color};
            display: block;
            border-radius: 20px;
            box-shadow: 0 4px 6px rgba(0 0 0 / 10%);
            overflow: hidden;
            max-height: 100%;
            max-width: 100%;
            grid-area: card;
        }

        .product-text {
            flex-grow: 1;
            padding: 30px 25px;
            box-sizing: border-box;
            order: 2;
            justify-content: center;
        }

        .text {
            font-style: normal;
            font-weight: 400;
            font-size: 12px;
            line-height: 16px;
            margin-top: 11px;
            margin-bottom: 11px;
            letter-spacing: -0.01em;
            font-feature-settings: 'ss05' on;
        }

        .subtitle2 {
            font-style: normal;
            font-weight: 500;
            font-size: 16px;
            line-height: 21px;
            letter-spacing: -0.02em;
            font-feature-settings: 'ss05' on;
            margin-top: 11px;

        }

        .subtitle1 {
            font-style: normal;
            font-weight: 520;
            font-size: 36px;
            line-height: 40px;
            letter-spacing: -0.03em;
            font-feature-settings: 'tnum' on, 'lnum' on, 'ss05' on;

            /* Content/On Color */

        }

        picture {
            max-height:100%;
            width: 100%;
            box-sizing: border-box;
            order: 1;
        }

        img {
            max-height: 240px;
            max-width: 100%;
            right: 0;
            object-fit: cover;
            box-sizing: border-box;
        }
        `,
    md: css`
        display: flex;
        padding: 55px 32px;

        .card {
            display: flex;
            height: 363px;
        }

        #show-product-btn {
            max-width: 330px;
        }

        .product-text {
            padding: 50px 30px;
            order: ${({ textOrder }) => textOrder};
            width: calc(100% - 360px);
        }

        .text {
            font-size: 14px;
            line-height: 20px;
            margin-top: 15px;
            margin-bottom: 15px;
        }

        .subtitle2 {
            font-weight: 580;
            font-size: 18px;
            line-height: 24px;
            margin-top: 15px;
        }

        .subtitle1 {
            font-weight: 580;
            font-size: 40px;
            line-height: 48px;
        }

        picture {
            height:363px;
            width: 360px;
            display: flex;
            order: ${({ pictureOrder }) => pictureOrder};
        }

        img {
            max-height: 100%;
            height: 363px;
            width: 360px;
            object-fit: cover
        }
        `,
    lg: css`
            display: grid;
            padding: 55px 72px;

            .card {
                width: 100%;
                height: 478px;
            }

            .product-text {
                padding: 50px 30px;
                width: calc(100% - 564px);
            }

            .text {
                font-size: 14px;
                line-height: 20px;
                margin-top: 25px;
                margin-bottom: 25px;
            }

            .subtitle2 {
                font-weight: 580;
                font-size: 22px;
                line-height: 29px;
                margin-top: 25px;
            }

            .subtitle1 {
                font-style: normal;
                font-weight: 620;
                font-size: 45px;
                line-height: 50px;
            }

            picture {
                height:478px;
                width: 564px;
                right: 0;
            }

            img {
                height: 478px;
                width: 564px;
            }
        `,
    xl: css`
            justify-content: center;

            .product-text {
                padding: 72px;
            }

            .text {
                font-size: 16px;
            }

            .subtitle2 {
                font-weight: 580;
                font-size: 24px;
                line-height: 32px;
            }

            .subtitle1 {
                font-style: normal;
                font-weight: 620;
                font-size: 48px;
                line-height: 56px;
            }
        `,
  })}


`;

ProductCardContainer.displayName = 'ProductCardContainer';
