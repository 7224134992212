import React, { useMemo } from 'react';
import { useIntl } from 'react-intl';
import PropTypes from 'prop-types';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Box from '@nubank/nuds-web/components/Box/Box';

import FeedbackWrapper from './styles/FeedbackWrapper';

const FeedbackItem = ({ description, owner, location }) => {
  const { formatMessage } = useIntl();

  const ownerMsg = useMemo(() => formatMessage(
    { id: owner },
  ));
  const locationMsg = useMemo(() => formatMessage(
    { id: location },
  ));

  const subtitle = useMemo(() => formatMessage(
    { id: 'CUSTOMER_FEEDBACK.OWNER.TITLE' },
    { owner: ownerMsg, location: locationMsg },
  ));

  return (
    <FeedbackWrapper
      marginBottom={{ xs: 48, lg: 84 }}
      paddingHorizontal={{ xs: 24, lg: 282 }}
    >
      <Typography
        variant="heading2"
        color="black"
        colorVariant="light"
        tag="q"
        intlKey={description}
      />

      <Box
        marginTop={{ xs: 12, md: 48 }}
      >
        <Typography
          variant="subtitle1"
          strong
          color="black"
          colorVariant="light"
          gutterBottom={0.5}
          dangerouslySetInnerHTML={{ __html: subtitle }}
        />
      </Box>
    </FeedbackWrapper>
  );
};

FeedbackItem.propTypes = {
  description: PropTypes.string.isRequired,
  location: PropTypes.string.isRequired,
  owner: PropTypes.string.isRequired,
};

export default FeedbackItem;
