import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import Box from '@nubank/nuds-web/components/Box/Box';
import Typography from '@nubank/nuds-web/components/Typography/Typography';
import Collapse from '@nubank/nuds-web/components/Collapse/Collapse';
import { useIntl } from 'react-intl';
import { css } from 'styled-components';

const FAQ = ({
  title,
  questions,
  rate,
  cdtMinOpeninng,
  backgroundColor,
}) => (
  <Box
    tag="section"
    backgroundColor={backgroundColor}
    display="flex"
    flexDirection="column"
    paddingHorizontal={{ xs: '8x', lg: '16x' }}
    paddingVertical={{ xs: '48px', lg: '64px' }}
    marginTop={{ md: '0', lg: '0' }}
  >
    <Typography
      intlKey={title}
      variant="heading2"
    />

    {questions.map(({
      question, answer, href, initiallyOpen, target = '_blank',
    }) => {
      const { formatMessage } = useIntl();
      let hrefUrl = '';
      if (href) {
        hrefUrl = useMemo(() => formatMessage({ id: href }));
      }
      return (
        <Box
          marginTop={{ xs: '16x', md: 60, lg: 56 }}
          key={`box-${question}`}
        >
          <Collapse
            id={question}
            initiallyOpen={initiallyOpen}
          >
            <Collapse.Header>
              <Typography
                color="black.light"
                variant="subtitle1"
                strong
                intlKey={question}
              />
            </Collapse.Header>
            <Collapse.Body>
              <Typography
                variant="subtitle1"
                intlKey={answer}
                css={css`
                  span {
                    font-weight: bold;
                  }

                  a {
                    text-decoration: underline;
                  }
                `}
                intlValues={{
                  cdtMinOpeninng,
                  rate,
                  span: msg => (
                    <span>{ msg }</span>
                  ),
                  p: msg => (
                    <>
                      { msg }
                      <br />
                    </>
                  ),
                  a: msg => (
                    <a href={hrefUrl} target={target} rel="noreferrer">{ msg }</a>
                  ),
                  br: <br />,
                }}
              />
            </Collapse.Body>
          </Collapse>
        </Box>
      );
    })}
  </Box>
);

FAQ.defaultProps = {
  rate: 11,
  cdtMinOpeninng: '$50.000',
  backgroundColor: 'white',
};

FAQ.propTypes = {
  backgroundColor: PropTypes.string,
  cdtMinOpeninng: PropTypes.string,
  questions: PropTypes.arrayOf(PropTypes.shape({
    answer: PropTypes.string.isRequired,
    initiallyOpen: PropTypes.bool.isRequired,
    question: PropTypes.string.isRequired,
  })).isRequired,
  rate: PropTypes.number,
  title: PropTypes.string.isRequired,
};

export default FAQ;
