import React from 'react';
import PropTypes from 'prop-types';

import MultiProductFooter from '../../patterns/MultiProductFooter/MultiProductFooter';
import Menu from '../../components/Menu/Menu';
import RegistrationDrawer from '../../patterns/RegistrationDrawer/RegistrationDrawer';
import MainContent from '../../components/MainContent/MainContent';
import HeaderCTA from '../../components/HeaderCTA/HeaderCTA';
import WebsiteSEO from '../../components/SEO/websiteSEO';
import MenuHeader from '../../components/MenuHeader/MenuHeader';

export const headerLayouts = {
  NO_APPLICATION: {
    showHeaderCTA: false,
  },
  NO_APPLICATION_NO_MENU: {
    showHeaderCTA: false,
    showHeaderMenu: false,
    showHeaderTopMenu: false,
  },
  ALL: {
    showHeaderCTA: true,
    showHeaderMenu: true,
    showHeaderTopMenu: true,
  },
};

export const footerLayouts = {
  NONE: {
    showFooter: false,
  },
  ALL: {
    showFooter: true,
  },
};

const hocDefaultOptions = {
  routeKey: 'HOME',
  header: {
    layout: headerLayouts.ALL,
    cta: <HeaderCTA />,
  },
  footer: footerLayouts.ALL,
};

const HeaderComponent = ({
  hocOptions,
}) => {
  const { components } = hocOptions;
  const CustomHeader = components?.header;

  if (CustomHeader) {
    return <CustomHeader />;
  }

  const { header: hocHeaderOptions } = hocOptions;
  const header = { ...hocDefaultOptions.header, ...hocHeaderOptions };

  const {
    cta, layout, nuLogoHref,
  } = header;
  const {
    showHeaderCTA, showHeaderMenu, showHeaderTopMenu, evalExpShowHeaderCTA, experiment,
  } = layout;

  return (
    <MenuHeader
      showCTA={showHeaderCTA}
      evalExpShowHeaderCTA={evalExpShowHeaderCTA}
      CTA={cta}
      showMenuBtn={showHeaderMenu}
      showHeaderTopMenu={showHeaderTopMenu}
      nuLogoHref={nuLogoHref}
      experiment={experiment}
    />
  );
};

HeaderComponent.propTypes = {
  hocOptions: PropTypes.shape({
    components: PropTypes.shape({
      header: PropTypes.shape({
        cta: PropTypes.element,
        nuLogoHref: PropTypes.string,
      }),
    }),
    header: PropTypes.shape({
      cta: PropTypes.element,
      nuLogoHref: PropTypes.string,
    }),
  }).isRequired,
};

const FooterComponent = ({ hocOptions }) => {
  const { components } = hocOptions;
  const CustomFooter = components?.footer;

  if (CustomFooter) {
    return <CustomFooter hocOptions={hocOptions} />;
  }

  const { footer: hocFooterOptions } = hocOptions;
  const footer = { ...hocDefaultOptions.footer, ...hocFooterOptions };
  const { showFooter } = footer;
  return (showFooter && <MultiProductFooter />);
};

FooterComponent.propTypes = {
  hocOptions: PropTypes.shape({
    components: PropTypes.shape({
      footer: PropTypes.func,
    }),
    footer: PropTypes.shape({
      showFooter: PropTypes.bool,
    }),
  }).isRequired,
};

const websitePage = (WrappedComponent, hocOptions) => {
  const { routeKey, openGraphResource } = { ...hocDefaultOptions, ...hocOptions };

  const WebsitePageComponent = props => (
    <>
      <WebsiteSEO routeKey={routeKey} openGraphResource={openGraphResource} />
      <HeaderComponent hocOptions={hocOptions} />

      <Menu />

      <RegistrationDrawer />

      <MainContent {...hocOptions}>
        <WrappedComponent {...props} />
      </MainContent>
      <FooterComponent hocOptions={hocOptions} />
    </>
  );

  WebsitePageComponent.getInitialProps = async cyx => {
    let pageProps = {};

    if (WrappedComponent.getInitialProps) {
      pageProps = await WrappedComponent.getInitialProps(cyx);
    }
    return {
      ...pageProps,
    };
  };

  return WebsitePageComponent;
};

export default websitePage;
