const MenuHeaderProps = {
  menuArray: [
    {
      id: 'home',
      link: 'CUCUTA.MENU.HOME.PATH',
      text: 'CUCUTA.MENU.HOME.LABEL',
      analyticsEvent: 'navigate_to_home',
      new: 'false',
      menuType: 'menu_link',
      showArrow: false,
    },
    {
      id: 'first_category',
      title: 'CUCUTA.MENU.FOR_YOU.LABEL',
      menuType: 'menu_navigation_category',
      subcategories: [
        {
          id: 'first_category_title',
          title: 'CUCUTA.MENU.PRODUCTS_CATEGORY.LABEL',
          links: [
            {
              id: 'credit_card',
              text: 'CUCUTA.MENU.CREDIT_CARD.LABEL',
              link: 'CUCUTA.MENU.CREDIT_CARD.PATH',
              analyticsEvent: 'navigate_to_credit_card',
              new: false,
            },
            {
              id: 'savings',
              text: 'CUCUTA.MENU.SAVINGS.LABEL',
              link: 'CUCUTA.MENU.SAVINGS.PATH',
              analyticsEvent: 'navigate_to_savings',
              new: false,
            },
          ],
        },
      ],
    },
    {
      id: 'about_us',
      text: 'CUCUTA.MENU.ABOUT_US.LABEL',
      link: 'CUCUTA.MENU.ABOUT_US.PATH',
      analyticsEvent: 'navigate_to_about_us',
      new: false,
      menuType: 'menu_link',
      showArrow: true,
    },
    {
      id: 'blog',
      text: 'CUCUTA.MENU.BLOG.LABEL',
      link: 'CUCUTA.MENU.BLOG.PATH',
      analyticsEvent: 'navigate_to_blog',
      new: false,
      menuType: 'menu_link',
      showArrow: true,
    },
  ],
};

export default MenuHeaderProps;
