import styled, { css } from 'styled-components';
import { breakpointsMedia } from '@nubank/nuds-web/styles/breakpoints';
import { nuDSColor } from '@nubank/nuds-web/styles/themeUtils';

export const HeroContainer = styled.section`
  display: flex;
  margin-top: 64px;
  flex-direction: column;
  background-color: ${nuDSColor('primary.defaultL10')};

  ${breakpointsMedia({
    xs: css``,
    md: css`
    flex-direction: row-reverse;
    margin-top: 56px;
    `,
    lg: css``,
  })}
`;

HeroContainer.displayName = 'HeroContainer';
