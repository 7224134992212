import React from 'react';
import Box from '@nubank/nuds-web/components/Box/Box';
import PropTypes from 'prop-types';
import { css } from 'styled-components';
import Typography from '@nubank/nuds-web/components/Typography/Typography';

import Carousel from '../../../../components/Carousel/Carousel';
import CarouselSlider from '../../../../components/Carousel/components/CarouselSlider/CarouselSlider';
import CarouselControl from '../../../../components/Carousel/components/CarouselControl/CarouselControl';

import feedbacksDefault from './feedbacks.json';
import FeedbackItem from './patterns/FeedBackItem/FeedbackItem';

const TRANSITION_DURATION_IN_MS = 500;

const CustomerFeedback = ({
  feedbacks, backGroungColor, title, isTicketExperienceExpOn,
}) => {
  const feedbackItems = feedbacks.map(({ owner, description, location }) => (
    <FeedbackItem
      key={owner}
      owner={owner}
      description={description}
      location={location}
    />
  ));

  return (
    <Box
      tag="section"
      padding={{ xs: '9rem 0 10rem 0', xl: '7.5rem 0 8rem 0' }}
      backgroundColor={backGroungColor}
    >
      <Typography
        intlKey={title}
        variant={isTicketExperienceExpOn ? 'heading4' : 'heading2'}
        tag={isTicketExperienceExpOn ? 'h4' : 'h2'}
        marginBottom={isTicketExperienceExpOn ? '16px' : '24x'}
        textAlign={{ xs: 'center', md: 'center' }}
        css={css`span {color: #820AD1;}`}
        intlValues={{
          br: (
            <>
              <br />
            </>
          ),
          span: msg => (
            <span>{msg}</span>
          ),
        }}
      />
      <Carousel
        items={feedbackItems}
        transitionDurationInMs={TRANSITION_DURATION_IN_MS}
      >
        <CarouselSlider />
        <CarouselControl
          colorSelected="primary"
          colorUnselected={['black', 'defaultT20']}
        />
      </Carousel>
    </Box>
  );
};

CustomerFeedback.defaultProps = {
  feedbacks: feedbacksDefault,
  backGroungColor: '#FFFFFF',
  title: '',
  isTicketExperienceExpOn: false,
};

CustomerFeedback.propTypes = {
  backGroungColor: PropTypes.string,
  feedbacks: PropTypes.arrayOf(PropTypes.shape({
    description: PropTypes.string.isRequired,
    location: PropTypes.string.isRequired,
    owner: PropTypes.string.isRequired,
  })),
  isTicketExperienceExpOn: PropTypes.bool,
  title: PropTypes.string,
};

export default CustomerFeedback;
